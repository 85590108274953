<template>
  <section id="email">
    <b-card>
      <b-card-header> Compose Mail </b-card-header>
      <b-card-body>
        <!-- Body -->
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              class="p-md-2 mr-2"
              @submit.prevent="handleSubmit(submitMail)"
              @reset.prevent="resetForm"
            >
              <!-- template -->
              <b-form-group
                label-cols-sm="1"
                label="Template"
                label-for="template"
              >
                <a class="btn-trigger-modal" v-b-modal.modal-1>
                  {{
                    formInfo.template.selected != null
                      ? formInfo.template.options[formInfo.template.selected]
                          .title
                      : ""
                  }}
                  <feather-icon size="24" icon="ChevronDownIcon" />
                </a>
                <b-modal id="modal-1" size="lg" hide-header hide-footer>
                  <div class="modal-top">Select Email Templates</div>
                  <div class="modal-container">
                    <div class="modal-search">
                      <feather-icon size="16" icon="SearchIcon" />
                      <b-form-input
                        id="search-template"
                        trim
                        type="search"
                        placeholder="Search key words"
                        v-model="formInfo.template.search"
                      />
                    </div>
                    <div
                      v-if="filterTemplateOptions.length > 0"
                      class="modal-options-wrapper"
                    >
                      <div
                        class="modal-options"
                        v-for="(option, index) in filterTemplateOptions"
                        :key="'templateOption' + index"
                        :class="
                          formInfo.template.select == index ? 'select' : ''
                        "
                      >
                        <div
                          href="#"
                          @click="
                            parseInt(collapse) >= 0
                              ? (collapse = '')
                              : (collapse = index)
                          "
                          v-b-toggle="'collapse-' + index"
                          class="drop-btn"
                        >
                          <h3 class="title">
                            {{ option.title }}
                            <div
                              v-if="collapse === index"
                              style="
                                float: right;
                                font-size: 25px;
                                margin-right: 3px;
                              "
                            >
                              -
                            </div>
                            <feather-icon v-else size="14" icon="PlusIcon" />
                          </h3>
                        </div>
                        <b-collapse :id="'collapse-' + index" class="">
                          <div
                            class="drop-item"
                            @click="formInfo.template.select = index"
                          >
                            <h4 class="name">{{ option.name }}</h4>
                            <div
                              class="options-content"
                              v-html="option.content"
                            ></div>
                          </div>
                        </b-collapse>
                      </div>
                    </div>
                    <div v-else class="empty-box">No Search Result</div>
                  </div>
                  <div class="modal-bottom">
                    <b-button
                      class="btn-select"
                      variant="primary"
                      type="submit"
                      @click="confirmTemplate"
                    >
                      <feather-icon size="14" icon="CheckCircleIcon" /> select
                    </b-button>
                    <b-button
                      class="btn-cancal"
                      variant="primary"
                      @click="$bvModal.hide('modal-1')"
                    >
                      <feather-icon size="14" icon="XCircleIcon" /> cancal
                    </b-button>
                  </div>
                </b-modal>
              </b-form-group>

              <!-- to -->
              <validation-provider
                #default="validationContext"
                name="To Email"
                rules="required"
              >
                <b-popover
                  target="popover-target-1"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>Press Enter after typing email.</template>
                </b-popover>
                <b-form-group
                  label-cols-sm="1"
                  label-for="email-to"
                  label="To"
                  class="required"
                  id="popover-target-1"
                >
                  <b-input-group>
                    <v-select
                      class="hide-dropdown"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      taggable
                      push-tags
                      v-model="formData.to"
                      placeholder=""
                      label="name_en"
                      :options="clientOptions"
                      :reduce="(option) => option.name_en"
                      style="width: 100%"
                    />
                    <b-input-group-append
                      v-b-modal.modal-2
                      style="
                        position: absolute;
                        right: 3px;
                        top: 3px;
                        padding: 5px 10px;
                        background: #c6e8f1;
                        color: white;
                        cursor: pointer;
                        z-index: 10;
                        border-radius: 5px;
                      "
                    >
                      <p style="margin: 0">Select Client</p>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>

                  <b-modal id="modal-2" size="lg" hide-header hide-footer>
                    <div class="modal-top">Select Client</div>
                    <div class="modal-container">
                      <div class="modal-search">
                        <feather-icon size="16" icon="SearchIcon" />
                        <b-form-input
                          id="search-client"
                          trim
                          type="search"
                          placeholder="Search client code / name /email"
                          v-model="formInfo.to.search"
                        />
                      </div>
                      <div
                        v-if="filterToOptions.length > 0"
                        class="modal-table-wrapper"
                      >
                        <div class="thead">
                          <div class="tr">
                            <div
                              class="th"
                              v-for="(th, index) in formInfo.to.fields"
                              :key="'thKey' + index"
                            >
                              {{ th }}
                            </div>
                          </div>
                        </div>
                        <div class="tbody">
                          <div
                            class="tr"
                            v-for="(to, index) in filterToOptions"
                            :key="'toOption' + index"
                            @click="selectClient(to.email)"
                            :class="
                              formData.to.includes(to.email) ? 'select' : ''
                            "
                          >
                            <div
                              class="td"
                              v-for="(item, index) in toOptionsKeyArray"
                              :key="'toOptionItem' + index"
                            >
                              {{ to[item] }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="empty-box">No Search Result</div>
                    </div>
                    <div class="modal-bottom">
                      <b-button
                        class="btn-select"
                        type="submit"
                        variant="primary"
                        @click="confirmTo"
                      >
                        <feather-icon size="14" icon="CheckCircleIcon" />
                        Select
                      </b-button>
                      <b-button
                        class="btn-cancal"
                        variant="primary"
                        @click="$bvModal.hide('modal-2')"
                      >
                        <feather-icon size="14" icon="XCircleIcon" /> Cancel
                      </b-button>
                    </div>
                  </b-modal>
                  <div class="other-input-list">
                    <a href="#" @click="handleCCClick()">Cc</a>
                    <a
                      href="#"
                      style="margin-left: 5px"
                      @click="handleBCCClick()"
                      >Bcc</a
                    >
                  </div>
                </b-form-group>
              </validation-provider>

              <!-- cc -->
              <validation-provider
                v-if="isCcOpen"
                #default="validationContext"
                name="Cc"
                rules="email"
              >
                <b-popover
                  target="popover-target-2"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>Press Enter after typing email.</template>
                </b-popover>
                <b-form-group
                  id="popover-target-2"
                  label-cols-sm="1"
                  label="CC"
                  label-for="cc-email"
                  class="required"
                >
                  <v-select
                    v-if="userOptions != null"
                    id="cc-email"
                    v-model="formData.cc"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="userOptions"
                    :reduce="(option) => option.email"
                    multiple
                    taggable
                    push-tags
                    placeholder="Add Cc"
                    :state="getValidationState(validationContext)"
                  />
                  <v-select
                    v-else
                    id="cc-email"
                    v-model="formData.cc"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    multiple
                    taggable
                    push-tags
                    placeholder="Add Cc"
                    :state="getValidationState(validationContext)"
                  />
                  <!-- <b-form-input v-else id="cc-email" trim placeholder="" v-model="formData.cc[0]"
                    :state="getValidationState(validationContext)" /> -->
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Bcc -->
              <validation-provider
                v-if="isBccOpen"
                #default="validationContext"
                name="Bcc"
                rules="email"
              >
                <b-popover
                  target="popover-target-3"
                  triggers="hover"
                  placement="top"
                >
                  <template #title>Press Enter after typing email.</template>
                </b-popover>
                <b-form-group
                  id="popover-target-3"
                  label-cols-sm="1"
                  label="Bcc"
                  label-for="bcc-email"
                  class="required"
                >
                  <v-select
                    v-if="userOptions != null"
                    id="bcc-email"
                    v-model="formData.bcc"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="userOptions"
                    :reduce="(option) => option.email"
                    multiple
                    taggable
                    push-tags
                    placeholder="Add Bcc"
                    :state="getValidationState(validationContext)"
                  />
                  <v-select
                    v-else
                    id="bcc-email"
                    v-model="formData.bcc"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    multiple
                    taggable
                    push-tags
                    placeholder="Add Bcc"
                    :state="getValidationState(validationContext)"
                  />
                  <!-- <b-form-input v-else id="bcc-email" trim placeholder="" v-model="formData.bcc"
                    :state="getValidationState(validationContext)" /> -->
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Subject -->
              <validation-provider
                #default="validationContext"
                name="subject"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="1"
                  label="Subject"
                  label-for="subject"
                  class="required"
                >
                  <b-form-input
                    id="subject"
                    trim
                    placeholder=""
                    v-model="formData.subject"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Message -->
              <b-form-group
                label-cols-sm="1"
                label="Message"
                label-for="message"
              >
                <quill-editor
                  class="email-messge-editor"
                  :style="{ height: '200px', borderRadius: '4px' }"
                  :options="editorOption"
                  v-model="formData.content"
                />
                <!-- <p class="error-msg" v-show="error.message">required</p> -->
              </b-form-group>
              <!-- Message Toolbar-->
              <div id="toolbar" slot="toolbar">
                <!-- Add a bold button -->
                <button class="ql-bold">Bold</button>
                <button class="ql-italic">Italic</button>
                <button class="ql-underline">Underline</button>
                <span class="ql-formats">
                  <button type="button" class="ql-align" value="">
                    <svg viewBox="0 0 18 18">
                      <line
                        class="ql-stroke"
                        x1="3"
                        x2="15"
                        y1="9"
                        y2="9"
                      ></line>
                      <line
                        class="ql-stroke"
                        x1="3"
                        x2="13"
                        y1="14"
                        y2="14"
                      ></line>
                      <line
                        class="ql-stroke"
                        x1="3"
                        x2="9"
                        y1="4"
                        y2="4"
                      ></line>
                    </svg>
                  </button>
                  <button type="button" class="ql-align" value="center">
                    <svg viewBox="0 0 18 18">
                      <line
                        class="ql-stroke"
                        x1="15"
                        x2="3"
                        y1="9"
                        y2="9"
                      ></line>
                      <line
                        class="ql-stroke"
                        x1="14"
                        x2="4"
                        y1="14"
                        y2="14"
                      ></line>
                      <line
                        class="ql-stroke"
                        x1="12"
                        x2="6"
                        y1="4"
                        y2="4"
                      ></line>
                    </svg>
                  </button>
                  <button type="button" class="ql-align" value="right">
                    <svg viewBox="0 0 18 18">
                      <line
                        class="ql-stroke"
                        x1="15"
                        x2="3"
                        y1="9"
                        y2="9"
                      ></line>
                      <line
                        class="ql-stroke"
                        x1="15"
                        x2="5"
                        y1="14"
                        y2="14"
                      ></line>
                      <line
                        class="ql-stroke"
                        x1="15"
                        x2="9"
                        y1="4"
                        y2="4"
                      ></line>
                    </svg>
                  </button>
                  <button type="button" class="ql-align" value="justify">
                    <svg viewBox="0 0 18 18">
                      <line
                        class="ql-stroke"
                        x1="15"
                        x2="3"
                        y1="9"
                        y2="9"
                      ></line>
                      <line
                        class="ql-stroke"
                        x1="15"
                        x2="3"
                        y1="14"
                        y2="14"
                      ></line>
                      <line
                        class="ql-stroke"
                        x1="15"
                        x2="3"
                        y1="4"
                        y2="4"
                      ></line>
                    </svg>
                  </button>
                </span>
              </div>
              <!-- submit button list-->
              <div
                class="mt-2 d-flex justify-content-between align-items-center"
              >
                <div class="d-flex align-items-center">
                  <b-button
                    class="btn-send full mobile-w100"
                    variant="primary"
                    type="submit"
                  >
                    <feather-icon
                      size="16"
                      class="mr-1"
                      icon="CheckCircleIcon"
                    />
                    Submit
                  </b-button>

                  <!-- file-->
                  <div class="file-wrapper d-flex">
                    <a
                      class="btn-trigger-input-file"
                      href="javascript:void(0)"
                      @click="$refs.pickFile.$el.childNodes[0].click()"
                    >
                      <feather-icon size="20" icon="PaperclipIcon" />
                    </a>
                    <div
                      v-if="!(documentData && documentData.original_name)"
                      class="file-list"
                    >
                      {{ fileNameList }}
                    </div>
                    <div v-else class="file-list">
                      <a
                        href="javascript:void(0)"
                        @click="handleDownload(documentData)"
                        >{{ documentData.original_name }}</a
                      >
                    </div>
                    <!-- <img v-if="blah" id="blah" :src="blah.src" alt="" /> -->
                  </div>
                </div>
                <a
                  @click="handleClearFile()"
                  href="javascript:void(0)"
                  class="btn-delete-file"
                >
                  <feather-icon size="20" icon="Trash2Icon" />
                </a>
              </div>
              <b-form-file
                id=""
                style="display: none"
                multiple
                ref="pickFile"
                @change="testFile"
                class="mb-2"
              >
              </b-form-file>
            </b-form>
          </validation-observer>

          <!-- schedule -->
          <b-modal id="modal-schedule-send" size="md" hide-header hide-footer>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(submitMail)"
                @reset.prevent="resetForm"
              >
                <div class="modal-top">Select date & time</div>
                <div class="modal-container">
                  <div class="modal-wrapper">
                    <!-- Schedule Date -->
                    <validation-provider
                      #default="validationContext"
                      name="Schedule send date"
                      rules="required"
                    >
                      <div class="picker-wrapper">
                        <flat-pickr
                          id="date-picker-schedule"
                          v-model="formData.schedule.date"
                          class="form-control"
                          :config="{
                            inline: true,
                            enableTime: true,
                            dateFormat: 'd-m-Y H:i K',
                          }"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </validation-provider>
                    <div class="choose-wrapper">
                      <div class="input-group align-items-center">
                        <b-form-checkbox
                          id="checkbox-every-week"
                          v-model="formData.schedule.isEveryWeek"
                          name="checkbox-every-week"
                        >
                          Schedule send every
                        </b-form-checkbox>
                        <v-select
                          v-model="formData.schedule.every.week"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="weekdays"
                          :clearable="false"
                          class="weekDays-selector d-inline-block mx-50"
                        />
                      </div>
                      <div class="input-group align-items-center">
                        <b-form-checkbox
                          id="checkbox-every-day"
                          v-model="formData.schedule.isEveryDay"
                          name="checkbox-every-day"
                        >
                          Schedule send every
                        </b-form-checkbox>
                        <b-form-input
                          class="input-days"
                          type="number"
                          :min="1"
                          trim
                          placeholder=""
                          v-model="formData.schedule.every.days"
                        ></b-form-input>
                        <span>days</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-bottom mt-2">
                  <b-button
                    class="btn-cancal"
                    variant="primary"
                    @click="$bvModal.hide('modal-schedule-send')"
                  >
                    <feather-icon size="14" icon="XCircleIcon" /> Cancal
                  </b-button>
                  <b-button
                    class="btn-submit-schedule-send mr-sm-5"
                    type="submit"
                    variant="primary"
                  >
                    <feather-icon size="14" icon="SendIcon" /> Schedule Send
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-overlay>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BForm,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BCollapse,
  BFormInvalidFeedback,
  VBToggle,
  BPopover,
} from "bootstrap-vue";

import vSelect from "vue-select";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { onMounted, ref } from "@vue/composition-api";
import emailTemplateStoreModule from "./emailTemplateStoreModule";
import store from "@/store";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardBody,
    BButton,
    BOverlay,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BCollapse,
    BPopover,
    vSelect,
    BFormInvalidFeedback,

    // Date Picker
    flatPickr,

    // Editor
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: ["document"],
  data() {
    return {
      documentData: {},
      loading: false,
      isCcOpen: false,
      isBccOpen: false,
      isScheduleOpen: false,
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: "#toolbar",
        },
      },
      collapse: "",
      blah: {
        src: "",
      },
    };
  },
  methods: {
    handleCCClick() {
      this.isCcOpen = !this.isCcOpen;
      if (this.isCcOpen == false) {
        this.formData.cc = [];
      }
    },
    handleBCCClick() {
      this.isBccOpen = !this.isBccOpen;
      if (this.isBccOpen == false) {
        this.formData.bcc = [];
      }
    },
    handleDownload(item) {
      this.loading = true;
      //this.$store.dispatch(this.DOCUMENT_APP_STORE_MODULE_NAME+'/fetchDocument', {id})
      axiosIns
        .get("/document", { params: { id: item.id }, responseType: "blob" })
        .then((response) => {
          this.loading = false;
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);
          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", item.original_name); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch(async (error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(await error.response.statusText),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    handleClearFile() {
      this.formData.attachment = [];
      this.documentData = {};
    },
    confirmTemplate: function () {
      if (this.formInfo.template.select != null) {
        const index = this.formInfo.template.select;
        this.formInfo.template.selected = index;
        this.formData.subject = this.formInfo.template.options[index].subject;
        this.formData.content = this.formInfo.template.options[index].content;
        this.$bvModal.hide("modal-1");
      } else {
        this.$bvToast.toast(`Please select a Template`, {
          title: "You Don't select any Template",
          autoHideDelay: 2000,
        });
      }
    },
    selectClient: function (email) {
      if (this.formData.to.includes(email)) {
        const index = this.formData.to.indexOf(email);
        this.formData.to.splice(index, 1);
      } else {
        this.formData.to.push(email);
      }
    },
    confirmTo: function () {
      this.$bvModal.hide("modal-2");
    },
    testFile: function (event) {
      const file = event.target.files;
      file.forEach((item) => {
        if (item.size < 1024 * 1024 * 2) {
          this.formData.attachment.push(item);
        } else {
          this.$bvToast.toast(`${item.name} too big (> 2MB)`, {
            title: `File Size To Big`,
            autoHideDelay: 2000,
          });
        }
      });
    },
    handleOpenSchedule: function () {
      if (this.formData.to) {
        this.$bvModal.show("modal-schedule-send");
      } else {
        this.$bvToast.toast(`Please specify at least one recipient.`, {
          title: "Error",
          autoHideDelay: 2000,
        });
      }
    },
    submitMail: function (method) {
      console.log("sending form data", this.formData);

      let newFormData = new FormData();

      if (this.documentData && this.documentData.id) {
        newFormData.append("document_id", this.documentData.id);
      } else {
        this.formData.attachment.forEach((item) => {
          newFormData.append("attachment[]", item);
        });
      }
      this.formData.to.forEach((item) => {
        newFormData.append("to[]", item);
      });
      this.formData.cc.forEach((item) => {
        newFormData.append("cc[]", item);
      });
      this.formData.bcc.forEach((item) => {
        newFormData.append("bcc[]", item);
      });
      // newFormData.append("message", this.formData.message);
      newFormData.append("content", this.formData.content);
      newFormData.append("subject", this.formData.subject);
      // newFormData.append("to", this.formData.to);
      // newFormData.append("cc", this.formData.cc);
      // newFormData.append("bcc", this.formData.bcc);
      newFormData.append("schedule", this.formData.schedule);
      console.log(newFormData);
      this.loading = true;
      store
        .dispatch("app-email/sendEmail", newFormData)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.$emit("refetch-data");
          this.formData.to = [];
          this.formData.cc = [];
          this.formData.bcc = [];
          this.formData.content = "";
          this.formData.subject = "";
          this.formData.schedule = {
            date: "",
            isEveryWeek: false,
            isEveryDay: false,
            every: {
              week: "",
              days: "",
            },
          };
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
  computed: {
    combinOptions() {
      return this.formInfo.template.options.map((item) => {
        console.log(item);
        item.content = item.content.concat(this.formInfo.template.info);
        return item;
      });
    },
    filterTemplateOptions() {
      return this.combinOptions.filter((item) => {
        return item.title
          .toLowerCase()
          .includes(this.formInfo.template.search.toLowerCase());
        return Object.values(item).some((value) => {
          console.log(value);
          return (
            value
              .toLowerCase()
              .indexOf(this.formInfo.template.search.toLowerCase()) > -1
          );
        });
      });
    },

    filterToOptions() {
      var self = this.formInfo.to;
      return this.formInfo.to.options.filter((item) => {
        return (
          item.name_en.toLowerCase().indexOf(self.search.toLowerCase()) >= 0 ||
          item.email.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
        );
      });
    },

    toOptionsKeyArray() {
      return Object.keys(this.formInfo.to.options[0]);
    },
    fileNameList() {
      // alert('ddd',this.formData.attachment)
      if (this.formData.attachment.length > 0) {
        const nameArray = this.formData.attachment.map((item) => {
          return item.name;
        });
        return nameArray.join(", ");
      } else {
        return;
      }
    },
  },
  mounted() {
    this.documentData = this.document;
  },
  created() {},
  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "app-email";

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(
        APP_CLIENT_STORE_MODULE_NAME,
        emailTemplateStoreModule
      );

    const data = {
      template: {
        search: "",
        selected: null,
        select: null,
        options: [],
        info: ``,
      },
      to: {
        search: "",
        selected: [],
        select: [],
        options: [],
        fields: ["Client ID", "Client Name", "Client Email"],
      },
    };

    const clientOptions = ref([]);
    const userOptions = ref([]);
    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", { client: true, user_email: true })
        .then((response) => {
          console.log(response);
          clientOptions.value = response.data.clients;
          userOptions.value = response.data.users;
          data.to.options = response.data.clients;
          console.log(data.to.options);
        });
    };

    const FetchTemplates = () => {
      store
        .dispatch("app-email/activeTemplates")
        .then((response) => {
          console.log("email templates response", response);
          const { email_templates } = response.data;
          console.log(email_templates);
          data.template.options = email_templates;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const formInfo = ref(data);

    FetchTemplates();
    refetchOption();

    let blankFormData = {
      to: [],
      cc: [],
      bcc: [],
      subject: "",
      content: "",
      attachment: [],
      schedule: {
        date: "",
        isEveryWeek: false,
        isEveryDay: false,
        every: {
          week: "",
          days: "",
        },
      },
    };

    const formData = ref(JSON.parse(JSON.stringify(blankFormData)));

    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    let weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    weekDays = ref(weekDays);

    return {
      formInfo,
      formData,
      weekDays,
      refFormObserver,
      getValidationState,
      resetForm,
      FetchTemplates,
      clientOptions,
      refetchOption,
      userOptions,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.hide-dropdown {
  ul {
    display: none !important;
  }
}

.weekDays-selector {
  .vs__open-indicator {
    fill: $primary-color-4;
  }
}

.picker-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 310px;
  margin: 0 auto;

  .flatpickr-calendar {
    top: 0px;
    margin: 0px auto 1rem;
    order: 1;
  }

  .flatpickr-input {
    max-width: 310px;
    margin: 0 auto;
    order: 2;

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: radial-gradient(#e0e0e0, rgba(224, 225, 226, 0.12));
      margin-top: 1rem;
    }
  }

  .invalid-feedback {
    order: 2;
  }
}

.email-messge-editor {
  .ql-snow .ql-editor {
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      padding: 2rem 0;
    }

    p {
      padding-bottom: 0.5rem;
    }
  }
}

.choose-wrapper {
  margin: 0 auto;
  max-width: 310px;

  .input-group {
    padding-top: 20px;

    label {
      margin: 0 0.3rem !important;
      color: #d0358f;
    }

    & > .form-control.input-days {
      flex: 0 auto;
      width: 50px;
      margin: 0 10px;
      border-radius: 6px !important;
    }

    .weekDate-selector {
      width: 136px;
      margin-right: 0px !important;
    }
  }
}

#email .col-form-label {
  min-width: 80px;
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.card-header {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}

label {
  font-size: 18px !important;
}

.btn-trigger-input-file {
  margin: 0 20px;
}

// quill
.ql-toolbar.ql-snow {
  border: 0px;
}

.btn-send {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  border-radius: 6px 0px 0px 6px;

  &.full {
    border-radius: 4px;
  }
}

.btn-drop-down-schedule {
  border-radius: 0 6px 6px 0px;
  padding-right: 1rem;
  padding-left: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.btn-schedule-open {
  position: absolute;
  top: -65px;
  left: -36px;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  border-radius: 6px;
  background: #fff;
  border: 0.5px solid #e0e0e0;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
}

.file-wrapper {
  position: relative;
  // width: calc(100% - 10.25rem);
  color: #a03071;

  .file-list {
    // width: 77%;
    vertical-align: middle;
  }

  img {
    display: inline;
    max-width: 150px;
  }

  .error-msg {
    padding-left: 10px;
  }
}

// .btn-delete-file {
//   position: absolute;
//   right: 20px;
//   top: 0px;
// }

.other-input-list {
  position: absolute;
  top: 10px;
  right: -50px;
}

@media screen and (max-width: 768px) {
  .file-wrapper {
    width: 100%;
    margin-top: 1rem;

    .btn-trigger-input-file {
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 575px) {
  .file-wrapper .file-list {
    width: 65%;
  }
}
</style>
